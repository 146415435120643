import i18next from 'i18next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { appPaths } from '@esg/config/app-paths.config';

export default function Home() {
  const router = useRouter();

  useEffect(() => {
    if (router.pathname === '/') {
      void router.push(
        '/' + i18next.language.substring(0, 2) + appPaths.getHomePagePath(),
      );
    }
  });

  return null;
}
